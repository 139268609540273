import React from 'react';

import Button from '@components/forms/Button';
import { useInspectionDetailStore } from '@stores';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import _intersection from 'lodash/intersection';
import { Chip, ChipProps } from '@material-tailwind/react';
import { DOCUMENT_TYPE } from '@constants';
type Props = {
  onPrevious: () => void;
  onClose: () => void;
  onNext: () => void;
};
const TextChip = (props: ChipProps) => {
  return (
    <Chip
      color="gray"
      variant="outlined"
      className="rounded-full border-grey-200 bg-grey-50 text-grey-700 normal-case w-fit text-sm font-medium"
      {...props}
    />
  );
};

const ReviewStep: React.FC<Props> = ({ onPrevious, onNext }) => {
  const { inspectionDetailData } = useInspectionDetailStore();

  return (
    <div>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Review</h3>
      <p className="text-grey-600 mb-3 text-sm">
        Please review the inspection details carefully before proceeding to
        submission.
      </p>
      <div className="p-8 rounded-lg border border-grey-200 bg-grey-50 mt-4 flex flex-col gap-8">
        <div className="mt-6 font-medium text-grey-800">
          <p className="mb-0.5 font-semibold text-md">
            Lender: {inspectionDetailData.lender.lenderName}
          </p>
          <p className="mb-0.5 font-semibold ">
            Inspection template: {inspectionDetailData?.lender?.template?.name}
          </p>
          <p className="mb-0.5 font-semibold">
            Lender reference number: {inspectionDetailData.lenderReference}
          </p>
        </div>
        <div className="font-medium text-grey-800">
          <p className="text-sm text-grey-600 font-medium mb-3">
            Requested Documentation
          </p>

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.SELLER_ID,
            LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
            LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
            LenderDocumentTypeEnum.FACE
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Seller Identification
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.SELLER_ID,
                  LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                  LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                  LenderDocumentTypeEnum.FACE
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return doc && doc.value ? (
                    <TextChip key={doc?.value} value={doc?.label} />
                  ) : null;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.ASSET_BACK,
            LenderDocumentTypeEnum.ASSET_FRONT,
            LenderDocumentTypeEnum.ASSET_LEFT,
            LenderDocumentTypeEnum.ASSET_RIGHT,
            LenderDocumentTypeEnum.INTERNAL_FRONT,
            LenderDocumentTypeEnum.INTERNAL_BACK,
            LenderDocumentTypeEnum.INTERNAL_BOOT,
            LenderDocumentTypeEnum.VIN,
            LenderDocumentTypeEnum.REGO_PLATE,
            LenderDocumentTypeEnum.ODOMETER
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Vehicle
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.ASSET_BACK,
                  LenderDocumentTypeEnum.ASSET_FRONT,
                  LenderDocumentTypeEnum.ASSET_LEFT,
                  LenderDocumentTypeEnum.ASSET_RIGHT,
                  LenderDocumentTypeEnum.INTERNAL_FRONT,
                  LenderDocumentTypeEnum.INTERNAL_BACK,
                  LenderDocumentTypeEnum.INTERNAL_BOOT,
                  LenderDocumentTypeEnum.VIN,
                  LenderDocumentTypeEnum.REGO_PLATE,
                  LenderDocumentTypeEnum.ODOMETER
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.REGO_PAPERS
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Registration Certificate
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.REGO_PAPERS
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.BANK_STATEMENTS,
            LenderDocumentTypeEnum.PAYOUT_LETTER
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Financial Documentation
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.BANK_STATEMENTS,
                  LenderDocumentTypeEnum.PAYOUT_LETTER
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.ROADWORTHY_CERT,
            LenderDocumentTypeEnum.SAFETY_CERT
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
              Roadworthy / Safety Certificate
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.ROADWORTHY_CERT,
                  LenderDocumentTypeEnum.SAFETY_CERT
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.PPSR,
            LenderDocumentTypeEnum.NEVDIS,
            LenderDocumentTypeEnum.RAV,
            LenderDocumentTypeEnum.GLASSES
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                External Verifications
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.PPSR,
                  LenderDocumentTypeEnum.NEVDIS,
                  LenderDocumentTypeEnum.RAV,
                  LenderDocumentTypeEnum.GLASSES
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
        </div>

        <div className="font-medium text-grey-800">
          <p className="text-sm text-grey-600 font-medium">Seller</p>
          {inspectionDetailData.seller && (
            <>
              {inspectionDetailData.seller.firstName && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.firstName}{' '}
                  {inspectionDetailData.seller.lastName}
                </p>
              )}
              {inspectionDetailData.seller.email && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.email}
                </p>
              )}
              {inspectionDetailData.seller.mobile && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.mobile}
                </p>
              )}
              {inspectionDetailData.seller.address && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.address}
                </p>
              )}
            </>
          )}
        </div>
        {inspectionDetailData?.lender?.template?.buyerRequired && (
          <div className="font-medium text-grey-800 ">
            <p className="text-sm text-grey-600 font-medium">Buyer</p>
            {(inspectionDetailData.buyer.firstName ||
              inspectionDetailData.buyer.lastName) && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.firstName +
                  ' ' +
                  inspectionDetailData.buyer.lastName}
              </p>
            )}
            {inspectionDetailData.buyer.email && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.email}
              </p>
            )}
            {inspectionDetailData.buyer.email && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.mobile}
              </p>
            )}
            {inspectionDetailData.buyer.address && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.address}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-4 gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            className="text-white bg-primary !text-sm"
            onClick={onNext}
            disabled={
              !(
                inspectionDetailData.seller.isDone &&
                (inspectionDetailData?.lender?.template?.buyerRequired
                  ? inspectionDetailData.buyer.isDone
                  : true) &&
                inspectionDetailData.documentValidation &&
                inspectionDetailData.lender.isDone
              )
            }>
            Submit Inspection
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
