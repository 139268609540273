import { useLocation } from 'react-router-dom';
import ProfileMenu from '@components/widgets/ProfileMenu';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-tailwind/react';
import { ReactComponent as BellIcon } from '@assets/icons/bell_icon.svg';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import { TITLE_PATH_MAPPINGS } from '@constants';
import _find from 'lodash/find';
import { useMediaQuery } from 'usehooks-ts';
import clsx from 'clsx';
const Header = () => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 720px)');
  const { pathname } = location;
  const navigate = useNavigate();
  const back = () => {
    if (pathname.includes('/inspections/detail')) {
      navigate('/inspections');
    } else if (pathname.includes('/verifications/detail')) {
      navigate('/verifications');
    } else {
      navigate(-1);
    }
  };
  return (
    <div
      className={clsx(
        'flex-col bg border-b border-b-grey-200 w-full',
        isMobile ? 'hidden' : 'md:flex'
      )}>
      <div className="py-2 px-8 h-[72px] flex justify-between items-center">
        <div className="flex-1 justify-start">
          <h1 className="font-semibold text-2xl text-gray-900 whitespace-nowrap">
            {_find(TITLE_PATH_MAPPINGS, ['path', pathname])?.title}
            {!TITLE_PATH_MAPPINGS.some((e) => e.path === pathname) && (
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={back}>
                <LeftIcon className="mr-2 rotate-180" />
                <p className="">Back</p>
              </div>
            )}
          </h1>
        </div>
        <div className="flex items-center justify-end w-full">
          <div className="flex items-center gap-2">
            <div className="hidden md:block">
              <ProfileMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
