import React, { useState, useEffect, useRef } from 'react';
import Modal from '@components/widgets/Modal';
import Button from '@components/forms/Button';
import { Chip } from '@material-tailwind/react';
import {
  CREATE_INSPECTION_TEMPLATE,
  LIST_INSPECTION_TEMPLATES,
  UPDATE_INSPECTION_TEMPLATE,
  post
} from '@services';
import { DOCUMENT_TYPE } from '@constants';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import { useForm } from 'react-hook-form';
import { Input } from '@components/forms';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
type Props = {
  open: boolean;
  param?: any;
  onClose: () => void;
};

const CreateDocumentRequirement: React.FC<Props> = ({
  open,
  param: initialTemplate,
  onClose
}) => {
  const {
    register,
    setError,
    setFocus,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm();
  const [asset, setAsset] = useState('CAR');
  const [selectedDocuments, setSelected] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [showSuccessMsg, setSuccessMsg] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [template, setTemplate] = useState<any>(initialTemplate);
  const setLenderDocuments = (data: any) => {
    const itemElement = containerRef.current?.children[0] as HTMLElement;
    // if (!asset) {
    //   setError('asset', {
    //     type: 'custom',
    //     message: 'Please select an asset type.'
    //   });
    //   itemElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   setFocus('asset');
    //   return;
    // }
    if (selectedDocuments.length === 0) {
      setError('selectedDocuments', {
        type: 'custom',
        message: 'Please select at least one document.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }
    if (template && template.templateId) {
      post(
        UPDATE_INSPECTION_TEMPLATE,
        {
          assetType: 'CAR',
          templateId: template.templateId,
          name: data.name,
          documentTypes: selectedDocuments
        },
        false
      )
        .then((data) => {
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
            onClose();
          }, 3000);
        })
        .catch((e: any) => {
          alert(e.response.data.error.message);
        });
    } else {
      const userId = localStorage.getItem('userId');

      const lenders = localStorage.getItem(`lenders_${userId}`);
      if (lenders != null) {
        const lender = JSON.parse(lenders)[0];
        const organisationId = lender.organisationId;
        post(
          CREATE_INSPECTION_TEMPLATE,
          {
            assetType: 'CAR',
            lenderOrganisationId: organisationId,
            name: data.name,
            documentTypes: selectedDocuments
          },
          false
        )
          .then((data) => {
            setSuccessMsg(true);
            setTemplate(data);
            setTimeout(() => {
              setSuccessMsg(false);
              onClose();
            }, 3000);
          })
          .catch((e: any) => {
            alert(e.response.data.error.message);
          });
      }
    }
  };
  const removeItems = (key: any) => {
    const filters = selectedDocuments.filter(
      (item) =>
        item !== key &&
        ![
          LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
          LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
          LenderDocumentTypeEnum.FACE
        ].includes(item as LenderDocumentTypeEnum)
    );
    setSelected(filters);
    const removeOptions = selectedOptions.filter((item) => item.value !== key);
    setSelectedOptions(removeOptions);
  };
  const onMultiSelectChange = (e: { label: string; value: string }[]) => {
    setSelectedOptions(e);
    clearErrors();
    const selectValues = e.map((item) => item.value);
    setSelected(selectValues);
  };

  const ArrowRenderer = ({ expanded }: { expanded: any }) => (
    <div
      className={clsx(
        'grid place-items-center absolute top-2/4 right-2 pt-px w-5 h-5 text-blue-gray-400 rotate-0 -translate-y-2/4 transition-all',
        expanded && 'rotate-180'
      )}>
      <ArrowDownIcon />
    </div>
  );

  ArrowRenderer.propTypes = {
    expanded: PropTypes.bool
  };

  useEffect(() => {
    if (!open) return;
    if (initialTemplate && !showSuccessMsg) {
      setTemplate(initialTemplate);
      setSelected(initialTemplate.documentTypes);
      setValue('name', initialTemplate.name);
      setSelectedOptions(
        DOCUMENT_TYPE.filter((item) => {
          return initialTemplate.documentTypes.includes(item.value);
        })
      );
      return;
    }
    if (template === undefined && initialTemplate === undefined) {
      setSelected(Object.keys(LenderDocumentTypeEnum));
      return;
    } else if (template && template.templateId) {
      const userId = localStorage.getItem('userId');
      if (userId) {
        const lender = localStorage.getItem(`lenders_${userId}` as any);
        if (lender) {
          const lenderOrganisationId = JSON.parse(lender)[0].organisationId;
          post(LIST_INSPECTION_TEMPLATES, { lenderOrganisationId }).then(
            (res) => {
              if (res.templates) {
                const targetTemplate = res.templates.find(
                  (item: any) => item.templateId === template.templateId
                );
                setTemplate(targetTemplate);
                setSelected(targetTemplate.documentTypes);
                setValue('name', targetTemplate.name);
                setSelectedOptions(
                  DOCUMENT_TYPE.filter((item) => {
                    return targetTemplate.documentTypes.includes(item.value);
                  })
                );
              }
            }
          );
        }
      }
    }
  }, [initialTemplate, open]);
  return (
    <Modal
      title={
        template && template.templateId
          ? 'Edit inspection template'
          : 'Create inspection template'
      }
      size="md"
      open={open}
      onClose={onClose}>
      <div
        ref={containerRef}
        className="h-full flex flex-col  max-h-[calc(100vh-380px)] md:max-h-[48rem] min-h-[24rem] no-scrollbar scrolling-touch overflow-y-auto justify-between ">
        <div className="h-full flex flex-col scrolling-touch overflow-y-auto no-scrollbar pr-4">
          <div className="mb-4">
            <p className="text-grey-700 text-sm mb-1.5 font-semibold">
              Inspection template name
            </p>
            <Input
              type="text"
              {...register(`name`, { required: 'Name is required' })}
              placeholder="Inspection template name"
              onBlur={() => {
                clearErrors();
              }}
              icon={errors.name && <ErrorIcon />}
            />
            {errors.name && (
              <span className="text-error-light text-2sm mt-[6px]">
                {errors.name?.message?.toString()}
              </span>
            )}
          </div>
          {/* <div className="mb-4">
            <p className="text-grey-700 text-sm mb-1.5 font-semibold">
              Asset Type
            </p>
            <Select
              value={asset}
              onChange={handleAssetChange}
              placeholder="Select asset type"
              className="w-full !h-10 lg:flex items-center text-sm leading-6 text-grey-400 rounded-md ring-1 ring-grey-900/10 shadow-sm hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700">
              {ASSET_OPTIONS_FOR_CREATE.map((item, index) => (
                <Option
                  key={index}
                  value={item.value}
                  disabled={item.value != AssetTypesEnum.CAR}>
                  {item.label}
                </Option>
              ))}
            </Select>
            {errors.asset && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {errors.asset?.message?.toString()}
              </span>
            )}
          </div> */}
          <div className="mb-4">
            <p className="text-grey-700 text-sm mb-1.5 font-semibold">
              Add documents
            </p>
            <MultiSelect
              options={DOCUMENT_TYPE}
              value={selectedOptions}
              className="border-grey-300 mb-1.5 shadow-sm rounded-md w-full !h-10 text-sm text-grey-400 ring-1 ring-grey-900/10 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
              ArrowRenderer={ArrowRenderer}
              onChange={onMultiSelectChange}
              labelledBy="Add additional documents"
            />
            {errors.selectedDocuments && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {errors.selectedDocuments?.message?.toString()}
              </span>
            )}
            <p className="text-grey-600 text-sm">
              Select all the documents you require for your inspections.
            </p>
          </div>
          <div className="min-h-[380px]">
            {_intersection(selectedDocuments, [
              LenderDocumentTypeEnum.SELLER_ID
            ]).length > 0 && (
              <div className="mb-4">
                <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                  Seller Identification
                </p>
                <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                  {_intersection(selectedDocuments, [
                    LenderDocumentTypeEnum.SELLER_ID
                  ]).map((item: any) => {
                    const doc = _find(DOCUMENT_TYPE, ['value', item]);
                    return (
                      <Chip
                        key={doc?.value}
                        open={open}
                        className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                        variant="outlined"
                        color="gray"
                        value={doc?.label}
                        onClose={() => removeItems(doc?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {_intersection(selectedDocuments, [
              LenderDocumentTypeEnum.ASSET_BACK,
              LenderDocumentTypeEnum.ASSET_FRONT,
              LenderDocumentTypeEnum.ASSET_LEFT,
              LenderDocumentTypeEnum.ASSET_RIGHT,
              LenderDocumentTypeEnum.INTERNAL_FRONT,
              LenderDocumentTypeEnum.INTERNAL_BACK,
              LenderDocumentTypeEnum.INTERNAL_BOOT,
              LenderDocumentTypeEnum.VIN,
              LenderDocumentTypeEnum.REGO_PLATE,
              LenderDocumentTypeEnum.ODOMETER
            ]).length > 0 && (
              <div className="mb-4">
                <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                  Vehicle
                </p>
                <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                  {_intersection(selectedDocuments, [
                    LenderDocumentTypeEnum.ASSET_BACK,
                    LenderDocumentTypeEnum.ASSET_FRONT,
                    LenderDocumentTypeEnum.ASSET_LEFT,
                    LenderDocumentTypeEnum.ASSET_RIGHT,
                    LenderDocumentTypeEnum.INTERNAL_FRONT,
                    LenderDocumentTypeEnum.INTERNAL_BACK,
                    LenderDocumentTypeEnum.INTERNAL_BOOT,
                    LenderDocumentTypeEnum.VIN,
                    LenderDocumentTypeEnum.REGO_PLATE,
                    LenderDocumentTypeEnum.ODOMETER
                  ]).map((item: any) => {
                    const doc = _find(DOCUMENT_TYPE, ['value', item]);
                    return (
                      <Chip
                        key={doc?.value}
                        open={open}
                        className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                        variant="outlined"
                        color="gray"
                        value={doc?.label}
                        onClose={() => removeItems(doc?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {_intersection(selectedDocuments, [
              LenderDocumentTypeEnum.BANK_STATEMENTS,
              LenderDocumentTypeEnum.PAYOUT_LETTER
            ]).length > 0 && (
              <div className="mb-4">
                <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                  Financial Documentation
                </p>
                <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                  {_intersection(selectedDocuments, [
                    LenderDocumentTypeEnum.BANK_STATEMENTS,
                    LenderDocumentTypeEnum.PAYOUT_LETTER
                  ]).map((item: any) => {
                    const doc = _find(DOCUMENT_TYPE, ['value', item]);
                    return (
                      <Chip
                        key={doc?.value}
                        open={open}
                        className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                        variant="outlined"
                        color="gray"
                        value={doc?.label}
                        onClose={() => removeItems(doc?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {_intersection(selectedDocuments, [
              LenderDocumentTypeEnum.ROADWORTHY_CERT
            ]).length > 0 && (
              <div className="mb-4">
                <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                  Roadworthy / Safety Certificate
                </p>
                <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                  {_intersection(selectedDocuments, [
                    LenderDocumentTypeEnum.ROADWORTHY_CERT
                  ]).map((item: any) => {
                    const doc = _find(DOCUMENT_TYPE, ['value', item]);
                    return (
                      <Chip
                        key={doc?.value}
                        open={open}
                        className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                        variant="outlined"
                        color="gray"
                        value={doc?.label}
                        onClose={() => removeItems(doc?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {_intersection(selectedDocuments, [
              LenderDocumentTypeEnum.PPSR,
              LenderDocumentTypeEnum.RAV,
              LenderDocumentTypeEnum.NEVDIS,
              LenderDocumentTypeEnum.GLASSES
            ]).length > 0 && (
              <div className="mb-4">
                <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                  External Verifications
                </p>
                <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                  {_intersection(selectedDocuments, [
                    LenderDocumentTypeEnum.PPSR,
                    LenderDocumentTypeEnum.RAV,
                    LenderDocumentTypeEnum.NEVDIS,
                    LenderDocumentTypeEnum.GLASSES
                  ]).map((item: any) => {
                    const doc = _find(DOCUMENT_TYPE, ['value', item]);
                    return (
                      <Chip
                        key={doc?.value}
                        open={open}
                        className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                        variant="outlined"
                        color="gray"
                        value={doc?.label}
                        onClose={() => removeItems(doc?.value)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 justify-end">
          {showSuccessMsg && (
            <div className="text-success-700 sticky font-medium text-sm text-right my-3">
              Inspection templates saved!
            </div>
          )}
          <div className="flex gap-4 justify-end">
            <Button
              className="font-semibold bg-white border-grey-300 text-grey-700 w-auto capitalize"
              variant="outlined"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="font-semibold bg-primary w-auto capitalize"
              onClick={() => {
                handleSubmit(setLenderDocuments, () => {
                  const itemElement = containerRef.current
                    ?.children[0] as HTMLElement;
                  itemElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                  });
                })();
              }}>
              Save and Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateDocumentRequirement;
