import _map from 'lodash/map';
import { IDocProps } from '@interface';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow_grey_up_right_icon.svg';
import StatusChip from '@components/widgets/StatusChip';
import {
  INSPECTION_SECURITY_CHECK_VARIANT,
  INSPECTION_SECURITY_CHECK_LABEL_STATUS,
  SECURITY_CHECK_LIST
} from '@constants';
import {
  BankSecurityCheckEnum,
  InspectionSecurityStatusEnum,
  RegoSecurityCheckEnum
} from '@types';
import clsx from 'clsx';
import { useInspectionDetailStore } from '@stores';
const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

const Variants = {
  success_light:
    'bg-success-50 border-success-500 active:bg-success-800 hover:bg-success-800',
  info_light:
    'border-grey-300 bg-grey-25 hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200',
  warning_light:
    'border-warning-500 bg-warning-50 active:bg-warning-700 hover:bg-warning-700',
  error_light:
    'border-error-400 bg-error-50 active:!bg-error-100 hover:bg-error-100',
  none_light:
    'border-grey-300 bg-grey-50 hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200'
};

export type SecurityCheckVariant =
  | 'success_light'
  | 'none_light'
  | 'info_light'
  | 'warning_light'
  | 'info_light';
const SecurityCheck = ({ docType, data, activeStep }: IDocProps) => {
  const {
    setActivePanel,
    setCollapse,
    inspectionDetailData,
    setActivity,
    setRightBarStatus,
    setVariant,
    setStatusChipLabel
  } = useInspectionDetailStore();
  let securityCheckList = SECURITY_CHECK_LIST[parseInt(activeStep) - 1];

  // Remove BANK_STATEMENT_ADDRESS if SELLER_ID is not present
  // When the Driver’s Licence task is not included in the inspection, this check cannot be performed.  In the below example, I’ve completed and inspection without the DL task, and the address check returned UNAVAILABLE
  // In this case, please hide this ‘Match Licence Address’ check panel.
  if (!inspectionDetailData.documentTypes.includes('SELLER_ID')) {
    securityCheckList = securityCheckList.filter(
      (item) => item !== 'BANK_STATEMENT_ADDRESS'
    );
  }

  // Only display the ‘VIN & Number Plate’ check if the inspection documentTypes include: VIN and NUMBER_PLATE.  Otherwise hide the panel.
  if (
    !inspectionDetailData.documentTypes.includes('VIN') ||
    !inspectionDetailData.documentTypes.includes('REGO_PLATE')
  ) {
    securityCheckList = securityCheckList.filter((item) => item !== 'vin');
  }
  const handleClick = (
    status: any,
    key: string,
    type: string,
    documentObj: any,
    isNotMatch: boolean
  ) => {
    if (
      !(
        (status &&
          ![
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.noRegister,
            InspectionSecurityStatusEnum.noReport,
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.notFound,
            InspectionSecurityStatusEnum.match,
            InspectionSecurityStatusEnum.error
          ].includes(status)) ||
        (key === 'licenseAuthenticity' &&
          [
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed,
            InspectionSecurityStatusEnum.warning
          ].includes(status)) ||
        (type === 'NEVDIS_REGISTRATION' &&
          (status === InspectionSecurityStatusEnum.noRegister ||
            status === InspectionSecurityStatusEnum.discrepancyFound) &&
          documentObj.expiry &&
          documentObj.expiry !== '')
      )
    ) {
      return;
    }
    setCollapse(false);
    setActivePanel(key);
    setActivity(false);
    if (activeStep === '3') {
      //set right bar status for registration
      setRightBarStatus(InspectionSecurityStatusEnum.discrepancyFound);
    } else {
      setRightBarStatus(status);
    }
    setVariant(getVariant(status, type, documentObj));
    setStatusChipLabel(getLabel(status, type, documentObj, isNotMatch));
  };
  const getVariant = (status: string, type: string, documentObj: any) =>
    type === 'PPSR_ENCUMBRANCE' &&
    status === InspectionSecurityStatusEnum.reportFound
      ? 'warning_light'
      : ['RAV_COMPLIANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.reportFound
      ? 'success_light'
      : type === 'NEVDIS_REGISTRATION' &&
        activeStep !== '3' &&
        status === InspectionSecurityStatusEnum.reportFound &&
        documentObj.regoStatus ===
          InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
      ? 'success_light'
      : ['PPSR_ENCUMBRANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.noRecord
      ? 'success_light'
      : type === 'VEHICLE_PHOTOS' &&
        status === InspectionSecurityStatusEnum.notAvailable
      ? 'info_light'
      : type === 'NEVDIS_REGISTRATION' &&
        activeStep !== '3' &&
        status === InspectionSecurityStatusEnum.noReport
      ? 'info_light'
      : ['RAV_COMPLIANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.noReport
      ? 'info_light'
      : type === 'LICENSE_EDITS' &&
        status === InspectionSecurityStatusEnum.failed
      ? 'warning_light'
      : activeStep === '3' &&
        registrationPaperStatus &&
        type === 'NEVDIS_REGISTRATION'
      ? INSPECTION_SECURITY_CHECK_VARIANT[
          InspectionSecurityStatusEnum.discrepancyFound
        ]
      : INSPECTION_SECURITY_CHECK_VARIANT[
          status as InspectionSecurityStatusEnum
        ];

  // Helper function to get stolen/written off report count
  const getReportCountLabel = (type: string, details: any[]) => {
    const count = details.length;
    return count > 1 ? `${count} Reports Found` : '1 Report Found';
  };

  const getLabel = (
    status: string,
    type: string,
    documentObj: any,
    isNotMatch: boolean
  ) => {
    // Define common status-type combinations
    const statusTypeMap = {
      'LICENSE_EXPIRY:pass': 'Valid',
      'LICENSE_EXPIRY:failed': 'Expired',
      'REGO_EXPIRY:pass': 'Match',
      'REGO_EXPIRY:failed': 'Discrepancy found',
      'LICENSE_EDITS:failed': 'Warning',
      'BANK_STATEMENT_ADDRESS:failed': 'Discrepancy found'
    };

    const key = `${type}:${status}`;
    if (statusTypeMap[key as keyof typeof statusTypeMap]) {
      return statusTypeMap[key as keyof typeof statusTypeMap];
    }

    // Handle NEVDIS_REGISTRATION cases
    if (type === 'NEVDIS_REGISTRATION') {
      if (activeStep !== '3') {
        if (status === InspectionSecurityStatusEnum.expired) return 'Expired';
        if (status === InspectionSecurityStatusEnum.noReport)
          return 'Not Found';
        if (
          status === InspectionSecurityStatusEnum.reportFound &&
          documentObj.regoStatus ===
            InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
        ) {
          return 'Registered';
        }
      } else if (activeStep === '3' && registrationPaperStatus) {
        return INSPECTION_SECURITY_CHECK_LABEL_STATUS[
          InspectionSecurityStatusEnum.discrepancyFound
        ];
      }
    }

    // Handle stolen/written off reports
    if (
      ['NEVDIS_STOLEN', 'NEVDIS_WRITTEN_OFF'].includes(type) &&
      status === InspectionSecurityStatusEnum.reportFound
    ) {
      const details =
        type === 'NEVDIS_STOLEN'
          ? documentObj.stolenDetails
          : documentObj.writtenOffDetails;
      return getReportCountLabel(type, details);
    }

    // Handle PPSR and RAV cases
    if (['PPSR_ENCUMBRANCE', 'RAV_COMPLIANCE'].includes(type)) {
      if (status === InspectionSecurityStatusEnum.notRequested)
        return 'Disabled';
      if (type === 'PPSR_ENCUMBRANCE') {
        if (status === InspectionSecurityStatusEnum.reportFound) {
          return stringFormat(
            INSPECTION_SECURITY_CHECK_LABEL_STATUS[
              InspectionSecurityStatusEnum.registrations
            ],
            documentObj?.ppsrData?.length
          );
        }
        if (status === InspectionSecurityStatusEnum.noRecord)
          return 'No Registrations Found';
      }
      if (type === 'RAV_COMPLIANCE') {
        if (status === InspectionSecurityStatusEnum.noReport) return 'No Found';
        if (status === InspectionSecurityStatusEnum.reportFound)
          return 'Received';
      }
      if (status === InspectionSecurityStatusEnum.notPerformed)
        return 'Disabled';
    }

    // Default fallback
    return INSPECTION_SECURITY_CHECK_LABEL_STATUS[
      status as keyof typeof INSPECTION_SECURITY_CHECK_LABEL_STATUS
    ];
  };

  const registrationPaperStatus = !location.pathname.includes(
    '/demo/inspection'
  )
    ? inspectionDetailData.files.filter(
        (item: any) => item.documentType === 'REGO_PAPERS'
      ).length > 0
      ? true
      : false
    : false;

  // Helper function to check if key is in excluded list
  const isExcludedKey = (key: string) => {
    const excludedKeys = [
      'rav',
      'ppsr',
      'registration',
      'vehiclePhotos',
      'dataEdits',
      'stolen',
      'writtenOff',
      'licenseExpiry',
      'licenseEdits',
      'licenseFaceMatch',
      'licenseLiveness',
      'licenseAuthenticity',
      'plate',
      'vin',
      'expiry',
      'registrationAuthenticity',
      'bankAuthenticity',
      'regoCertificate',
      'bankStatement',
      'BANK_STATEMENT_ADDRESS'
    ];
    return excludedKeys.includes(key);
  };

  // Helper function to check if status is in excluded list
  const isExcludedStatus = (status: any) => {
    return [
      InspectionSecurityStatusEnum.notPerformed,
      InspectionSecurityStatusEnum.notAvailable,
      InspectionSecurityStatusEnum.error
    ].includes(status);
  };

  // Helper function to determine if label should be shown
  const shouldShowLabel = (key: string, status: string) => {
    // Special case for ppsr and rav
    if (
      (key === 'ppsr' || key === 'rav') &&
      status === InspectionSecurityStatusEnum.notPerformed
    ) {
      return true;
    }

    // Original conditions
    return (
      !isExcludedKey(key) ||
      !isExcludedStatus(status) ||
      (status !== InspectionSecurityStatusEnum.notPerformed &&
        key === 'licenseExpiry')
    );
  };

  return (
    <>
      <div className="text-md">
        <h1 className="text-black font-semibold">
          {activeStep === '1'
            ? 'Security Check'
            : activeStep === '2'
            ? 'Vehicle Check'
            : activeStep === '3'
            ? 'Vehicle / Registration Verification'
            : 'Bank Statement Checks'}
        </h1>
      </div>
      <div className="border-grey-200  w-full flex flex-col justify-center h-min">
        {securityCheckList.map((key: any, index) => {
          // Skip if VIN/REGO_PLATE check and neither document exists
          if (
            (!inspectionDetailData.documentTypes.includes('VIN') ||
              !inspectionDetailData.documentTypes.includes('REGO_PLATE')) &&
            key === 'vin' &&
            !inspectionDetailData.documentTypes.includes('NEVDIS')
          ) {
            return;
          }

          // Handle vehicle photos check
          const requiredPhotoTypes = [
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'INTERNAL_FRONT',
            'INTERNAL_BACK',
            'INTERNAL_BOOT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER'
          ];
          if (
            !requiredPhotoTypes.some((type) =>
              inspectionDetailData.documentTypes.includes(type)
            ) &&
            key === 'vehiclePhotos'
          ) {
            return;
          }

          const items: { [key: string]: any } =
            inspectionDetailData.inspectionChecks;

          // Set status to NOT_REQUESTED for vehicle checks when only NEVDIS is present
          if (
            activeStep === '2' &&
            inspectionDetailData.documentTypes.includes('NEVDIS') &&
            !requiredPhotoTypes.some((type) =>
              inspectionDetailData.documentTypes.includes(type)
            )
          ) {
            if (!items[key]) {
              items[key] = {
                status: InspectionSecurityStatusEnum.notRequested,
                type: key.toUpperCase()
              };
            }
          }

          let documentObj: any =
            items &&
            (activeStep === '3' && key === 'expiry'
              ? items['registrationExpiry']
              : activeStep === '3' && key === 'registrationAuthenticity'
              ? items['regoCertificate']
              : activeStep === '4'
              ? key === 'BANK_STATEMENT_ADDRESS'
                ? items['address']
                : items['bankStatement']
              : items[key]);
          let type = documentObj && documentObj['type'];
          const isNotMatch =
            activeStep === '2' &&
            key === 'vin' &&
            [
              items?.vin?.licencePlateMatch,
              items?.licencePlate?.vinMatch
            ].includes('DIFFERENT');
          let status =
            (documentObj && type === 'VEHICLE_VIN'
              ? isNotMatch
                ? InspectionSecurityStatusEnum.discrepancyFound
                : items?.vin?.licencePlateMatch ===
                    InspectionSecurityStatusEnum.match &&
                  items?.licencePlate?.vinMatch ===
                    InspectionSecurityStatusEnum.match
                ? InspectionSecurityStatusEnum.match
                : [items?.vin?.status, items?.licencePlate?.status].includes(
                    InspectionSecurityStatusEnum.noRecord
                  )
                ? InspectionSecurityStatusEnum.notFound
                : InspectionSecurityStatusEnum.notPerformed
              : ['REGO_CERTIFICATE', 'BANK_STATEMENT'].includes(type) &&
                [
                  RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT,
                  BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT
                ].includes(key)
              ? documentObj.dataEditsStatus
              : type === 'NEVDIS_REGISTRATION' &&
                documentObj.status ===
                  InspectionSecurityStatusEnum.notPerformed &&
                [items?.vin?.status, items?.licencePlate?.status].includes(
                  InspectionSecurityStatusEnum.noRecord
                )
              ? InspectionSecurityStatusEnum.notFound
              : documentObj?.status) ||
            InspectionSecurityStatusEnum.notPerformed;
          if (key === 'dataEdits') {
            documentObj = items['vin'];
            const isPlateMatch = items?.licencePlate?.ocrMatch;

            if (documentObj.ocrMatch || isPlateMatch) {
              if (
                documentObj.ocrMatch === 'MATCH' &&
                isPlateMatch === 'MATCH'
              ) {
                status = InspectionSecurityStatusEnum.pass;
              } else if (
                documentObj.ocrMatch === 'DIFFERENT' ||
                documentObj.ocrMatch === 'NEAR_MATCH' ||
                isPlateMatch === 'NEAR_MATCH' ||
                isPlateMatch === 'DIFFERENT'
              ) {
                status = InspectionSecurityStatusEnum.warning;
              } else {
                status = InspectionSecurityStatusEnum.notPerformed;
              }
            } else {
              status = InspectionSecurityStatusEnum.notPerformed;
            }
            type = 'DATA_EDITS';
          }
          if (!documentObj) return;
          const label = docType[key as keyof typeof docType];

          return (
            <div
              className={clsx(
                'flex border rounded-xl px-3.5 py-3 items-center justify-between mt-2 whitespace-nowrap  flex-wrap  items-start gap-y-[6px]',

                ((status &&
                  ![
                    InspectionSecurityStatusEnum.notPerformed,
                    InspectionSecurityStatusEnum.pass,
                    InspectionSecurityStatusEnum.noRegister,
                    InspectionSecurityStatusEnum.noReport,
                    InspectionSecurityStatusEnum.noRecord,
                    InspectionSecurityStatusEnum.notFound,
                    InspectionSecurityStatusEnum.match,
                    InspectionSecurityStatusEnum.error
                  ].includes(status)) ||
                  (key === 'licenseAuthenticity' &&
                    status === InspectionSecurityStatusEnum.pass) ||
                  (type === 'NEVDIS_REGISTRATION' &&
                    (status === InspectionSecurityStatusEnum.noRegister ||
                      status ===
                        InspectionSecurityStatusEnum.discrepancyFound) &&
                    documentObj.expiry &&
                    documentObj.expiry !== '') ||
                  (type === 'REGO_EXPIRY' &&
                    [
                      InspectionSecurityStatusEnum.pass,
                      InspectionSecurityStatusEnum.failed
                    ].includes(status) &&
                    documentObj.isExpired)) &&
                  'cursor-pointer',
                index === 0 && 'first:mt-0 cursor-default',
                ['PPSR_ENCUMBRANCE'].includes(type) &&
                  status === InspectionSecurityStatusEnum.reportFound
                  ? Variants['warning_light']
                  : ['PPSR_ENCUMBRANCE'].includes(type) &&
                    status === InspectionSecurityStatusEnum.noRecord
                  ? Variants['success_light']
                  : ['RAV_COMPLIANCE'].includes(type) &&
                    status === InspectionSecurityStatusEnum.reportFound
                  ? Variants['success_light']
                  : ['RAV_COMPLIANCE'].includes(type) &&
                    status === InspectionSecurityStatusEnum.noReport
                  ? Variants['info_light']
                  : type === 'NEVDIS_REGISTRATION' &&
                    activeStep !== '3' &&
                    status === InspectionSecurityStatusEnum.reportFound &&
                    documentObj.regoStatus ===
                      InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
                  ? Variants['success_light']
                  : type === 'NEVDIS_REGISTRATION' &&
                    activeStep !== '3' &&
                    status === InspectionSecurityStatusEnum.noReport
                  ? Variants['info_light']
                  : type === 'LICENSE_EDITS' &&
                    status === InspectionSecurityStatusEnum.failed
                  ? Variants['warning_light']
                  : type === 'VEHICLE_PHOTOS' &&
                    status === InspectionSecurityStatusEnum.notAvailable
                  ? Variants['info_light']
                  : activeStep === '3' &&
                    registrationPaperStatus &&
                    type === 'NEVDIS_REGISTRATION'
                  ? Variants[
                      INSPECTION_SECURITY_CHECK_VARIANT[
                        InspectionSecurityStatusEnum.discrepancyFound
                      ] as keyof typeof Variants
                    ]
                  : type === 'VEHICLE_PHOTOS' &&
                    status !== InspectionSecurityStatusEnum.warning &&
                    status !== InspectionSecurityStatusEnum.pass &&
                    status !== InspectionSecurityStatusEnum.notAvailable
                  ? 'hidden'
                  : Variants[
                      INSPECTION_SECURITY_CHECK_VARIANT[
                        status as InspectionSecurityStatusEnum
                      ] as keyof typeof Variants
                    ],
                status === InspectionSecurityStatusEnum.notRequested &&
                  key !== 'ppsr' &&
                  key !== 'rav' &&
                  'hidden'
              )}
              key={index}
              onClick={() =>
                handleClick(status, key, type, documentObj, isNotMatch)
              }>
              <div className="flex flex-row items-center whitespace-nowrap gap-3">
                {key === 'vehiclePhotos' && (
                  <span className="text-white bg-[#033E73] rounded-[4px] px-2 py-[1px] text-sm ">
                    AI
                  </span>
                )}
                <span className="text-sm font-semibold">{label}</span>
              </div>
              {shouldShowLabel(key, status) && (
                <div className="flex flex-wrap flex-row justify-start gap-y-[6px] gap-x-6 whitespace-nowrap">
                  {((status &&
                    type !== 'VEHICLE_VIN' &&
                    ![
                      InspectionSecurityStatusEnum.notPerformed,
                      InspectionSecurityStatusEnum.pass,
                      InspectionSecurityStatusEnum.noRegister,
                      InspectionSecurityStatusEnum.noReport,
                      InspectionSecurityStatusEnum.noRecord,
                      InspectionSecurityStatusEnum.notFound,
                      InspectionSecurityStatusEnum.error
                    ].includes(status)) ||
                    (key === 'licenseAuthenticity' &&
                      [
                        InspectionSecurityStatusEnum.pass,
                        InspectionSecurityStatusEnum.failed,
                        InspectionSecurityStatusEnum.warning
                      ].includes(status)) ||
                    (type === 'NEVDIS_REGISTRATION' &&
                      (status === InspectionSecurityStatusEnum.noRegister ||
                        status ===
                          InspectionSecurityStatusEnum.discrepancyFound) &&
                      documentObj.expiry &&
                      documentObj.expiry !== '') ||
                    (type === 'VEHICLE_VIN' &&
                      status ===
                        InspectionSecurityStatusEnum.discrepancyFound) ||
                    (type === 'REGO_EXPIRY' &&
                      [InspectionSecurityStatusEnum.failed].includes(
                        status
                      ))) &&
                    !(key === 'BANK_STATEMENT_ADDRESS' && status === 'WARN') &&
                    !(
                      status === InspectionSecurityStatusEnum.notRequested &&
                      (key === 'ppsr' || key === 'rav')
                    ) &&
                    !(status === InspectionSecurityStatusEnum.notProvided) && (
                      <span className="flex flex-row font-semibold justify-center items-center text-sm text-grey-600">
                        <span className="underline">Details</span>
                        <ArrowIcon />
                      </span>
                    )}
                  <StatusChip
                    variant={getVariant(status, type, documentObj)}
                    clsName={clsx('border text-xs font-normal max-w-full')}
                    label={getLabel(status, type, documentObj, isNotMatch)}
                  />
                </div>
              )}
            </div>
          );
        })}
        <div></div>
      </div>
    </>
  );
};

export default SecurityCheck;
