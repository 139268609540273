import {
  AssetTypesEnum,
  LenderDocumentTypeEnum,
  LenderStatusEnum,
  SelectOption
} from '@types';
import { InspectionStatusEnum, AssetEnum, LenderEnum } from '@types';

export const INSPECTION_STATUS_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: InspectionStatusEnum.All
  },
  {
    label: 'Closed',
    value: InspectionStatusEnum.Completed
  },
  {
    label: 'Initiated',
    value: InspectionStatusEnum.Initiated
  },
  {
    label: 'Submitted',
    value: InspectionStatusEnum.Submitted
  },
  {
    label: 'In Progress',
    value: InspectionStatusEnum.InProgress
  }
];

export const ASSET_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: AssetEnum.All
  },
  {
    label: 'Car',
    value: AssetEnum.optionA
  },
  {
    label: 'Motor cycle',
    value: AssetEnum.optionB
  },
  {
    label: 'Campervan',
    value: AssetEnum.optionC
  }
];

export const ASSET_OPTIONS_FOR_CREATE: SelectOption[] = [
  {
    label: 'Car',
    value: AssetTypesEnum.CAR
  },
  {
    label: 'Motor cycle',
    value: AssetTypesEnum.MOTOR_CYCLE
  },
  {
    label: 'Campervan',
    value: AssetTypesEnum.CAMPERVAN
  },
  {
    label: 'Truck',
    value: AssetTypesEnum.TRUCK
  }
];

export const DOCUMENT_TYPE: SelectOption[] = [
  {
    label: 'Driver’s licence',
    value: LenderDocumentTypeEnum.SELLER_ID
  },
  {
    label: 'Photo vehicle back',
    value: LenderDocumentTypeEnum.ASSET_BACK
  },
  { label: 'Photo vehicle front', value: LenderDocumentTypeEnum.ASSET_FRONT },
  {
    label: 'Photo vehicle passenger’s side',
    value: LenderDocumentTypeEnum.ASSET_LEFT
  },
  {
    label: 'Photo vehicle driver’s side',
    value: LenderDocumentTypeEnum.ASSET_RIGHT
  },
  {
    label: 'Front seats',
    value: LenderDocumentTypeEnum.INTERNAL_FRONT
  },
  {
    label: 'Back seats',
    value: LenderDocumentTypeEnum.INTERNAL_BACK
  },
  {
    label: 'Boot',
    value: LenderDocumentTypeEnum.INTERNAL_BOOT
  },
  { label: 'VIN', value: LenderDocumentTypeEnum.VIN },
  { label: 'Number plate', value: LenderDocumentTypeEnum.REGO_PLATE },
  { label: 'Odometer', value: LenderDocumentTypeEnum.ODOMETER },
  {
    label: 'Registration Certificate',
    value: LenderDocumentTypeEnum.REGO_PAPERS
  },
  { label: 'Bank statement', value: LenderDocumentTypeEnum.BANK_STATEMENTS },
  {
    label: 'Roadworthy / Safety Certificate (Unregistered)',
    value: LenderDocumentTypeEnum.ROADWORTHY_CERT
  },
  { label: 'Payout Letter', value: LenderDocumentTypeEnum.PAYOUT_LETTER },
  { label: 'PPSR', value: LenderDocumentTypeEnum.PPSR },
  { label: 'NEVDIS', value: LenderDocumentTypeEnum.NEVDIS },
  { label: 'Vehicle compliance', value: LenderDocumentTypeEnum.RAV },
  { label: 'Extended  vehicle details', value: LenderDocumentTypeEnum.GLASSES }
];

export const LENDER_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: LenderEnum.All
  },
  {
    label: 'Maria',
    value: LenderEnum.optionA
  },
  {
    label: 'Steve Brewer',
    value: LenderEnum.optionB
  }
];

export const LENDER_STATUS_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Active',
    value: LenderStatusEnum.Active
  },
  {
    label: 'Pending',
    value: LenderStatusEnum.Pending
  },
  {
    label: 'Revoked',
    value: LenderStatusEnum.Revoked
  }
];

export const STATE: SelectOption[] = [
  {
    label: 'NSW',
    value: 'NSW'
  },
  {
    label: 'QLD',
    value: 'QLD'
  },
  {
    label: 'VIC',
    value: 'VIC'
  },
  {
    label: 'WA',
    value: 'WA'
  },
  {
    label: 'SA',
    value: 'SA'
  },
  {
    label: 'ACT',
    value: 'ACT'
  },

  {
    label: 'TAS',
    value: 'TAS'
  },
  {
    label: 'NT',
    value: 'NT'
  }
];
