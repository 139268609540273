import { useNavigate } from 'react-router-dom';
import { ReactComponent as RotateIcon } from '@assets/icons/rotate_icon.svg';
import StatusChip from '@components/widgets/StatusChip';
import {
  INSPECTION_STATUS_VARIANT,
  INSPECTION_STATUS_TEXT,
  INSPECTION_TABLE_ROWS
} from '@constants';
import Button from '@components/forms/Button';
import { InspectionStatusEnum } from '@types';
import Table, { TableHeader } from '@components/widgets/Table';
import ProgressBar from '@ramonak/react-progress-bar';
import _filter from 'lodash/filter';
import { format, parseISO } from 'date-fns';
import { useInspectionDetailStore } from '@stores';

import { useState, useEffect } from 'react';

const LandingTable = ({
  data,
  loadMoreData
}: {
  data: any;
  loadMoreData: (createdTime: string) => void;
}) => {
  const navigate = useNavigate();
  const { setInspectionDetailData, setSideCollapse, reset } =
    useInspectionDetailStore();
  const [visibleItems, setVisibleItems] = useState(40);
  const handleRowClick = (record: any) => {
    const currentData = _filter(INSPECTION_TABLE_ROWS, [
      'inspectionId',
      record.inspectionId
    ])[0];
    reset();
    setInspectionDetailData(currentData);
    setSideCollapse(true);
    navigate(`detail/${record.inspectionId}`);
  };

  const loadMore = () => {
    loadMoreData(visibleData[visibleData.length - 1].createdTime);
    setVisibleItems((prevVisibleItems: any) => prevVisibleItems + 40);
  };
  const visibleData = data.slice(0, visibleItems);
  const TABLE_HEAD: TableHeader[] = [
    {
      label: 'Created By',
      field: 'lender',
      render: (row) => {
        if (row.lender) {
          return row.lender.firstName + ' ' + row.lender.lastName;
        } else {
          return row.broker.firstName + ' ' + row.broker.lastName;
        }
      }
    },
    {
      label: 'Created Date',
      field: 'createdTime',
      render: (row) => {
        const formattedDate =
          row.createdTime && format(parseISO(row.createdTime), 'd MMM yyyy');
        return <span>{formattedDate}</span>;
      }
    },
    {
      label: 'Reference Number',
      field: 'lenderReference'
    },
    {
      label: 'Lender',
      field: 'lenderOrganisationName'
    },
    {
      label: 'Template',
      field: 'templateName'
    },
    {
      label: 'Inspection Number',
      field: 'friendlyId'
    },
    {
      label: 'Buyer',
      field: 'buyer',
      render: (row) =>
        row.buyer && row.buyer.firstName + ' ' + row.buyer.lastName
    },
    {
      label: 'Seller',
      field: 'seller',
      render: (row) =>
        row.seller && row.seller.firstName + ' ' + row.seller.lastName
    },
    {
      label: 'Status',
      field: 'status',
      render: (row) => (
        <StatusChip
          variant={
            INSPECTION_STATUS_VARIANT[row.status as InspectionStatusEnum]
          }
          label={INSPECTION_STATUS_TEXT[row.status as InspectionStatusEnum]}
        />
      )
    },
    {
      label: 'Progress',
      field: 'progress',
      render: (row: any) => {
        const progress = (row.stepsComplete / row.stepsTotal) * 100;
        return (
          <div className="flex items-center gap-2">
            <ProgressBar
              completed={progress}
              bgColor="#F63D68"
              width="181px"
              height="8px"
              isLabelVisible={false}
            />
            <span className="text-sm text-grey-600">
              {row.stepsComplete}/{row.stepsTotal}
            </span>
          </div>
        );
      }
    }
  ];
  const [tableHead, setTableHead] = useState(TABLE_HEAD);
  const [maxHeight, setMaxHeight] = useState(600);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const lender = localStorage.getItem(`lenders_${userId}` as any);
    if (lender) {
      setTableHead(TABLE_HEAD.filter((item) => item.label !== 'Lender'));
    }
  }, []);

  useEffect(() => {
    // Update maxHeight when window resizes
    const handleResize = () => {
      setMaxHeight(window.innerHeight - 320);
    };

    // Set initial maxHeight
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div>
        <Table
          headers={tableHead}
          data={visibleData}
          onClickRow={(row) => handleRowClick(row)}
          maxHeight={maxHeight}
        />
      </div>
      {visibleItems <= data.length && visibleData.length % 40 === 0 && (
        <div className="flex justify-center pt-6">
          <Button
            className="bg-white border-grey-300 text-grey-600 text-sm flex items-center gap-2"
            variant="outlined"
            onClick={loadMore}>
            <RotateIcon />
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

export default LandingTable;
