import DocumentCard from '@pages/Inspections/components/RightPanelCards/DocumentCard';
import { useInspectionDetailStore } from '@stores';
import { DocumentFormEnum } from '@types';

const Cert = () => {
  const { activePanel } = useInspectionDetailStore();

  return (
    <>
      {activePanel.activeSection === 'ROADWORTHY_CERT' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_CERTIFICATE} />
      )}
      {activePanel.activeSection === 'PAYOUT_LETTER' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_CERTIFICATE} />
      )}
    </>
  );
};

export default Cert;
