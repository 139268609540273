export const DETAILS_DRIVER_LICENSE_FRONT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_DRIVER_LICENSE_BACK = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_DRIVER_LICENSE_SELFIE = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_VIN = [
  {
    key: 'vin',
    displayName: 'VIN',
    keyPath: 'fields2.vin.vin'
  }
];
export const DETAILS_VEHICLE_VIN_LOCATION = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_PLATE = [
  {
    key: 'rego',
    displayName: 'Number Plate',
    keyPath: 'fields2.licencePlate.rego'
  },
  {
    key: 'state',
    displayName: 'State / Territory',
    keyPath: 'fields2.licencePlate.state'
  }
];
export const DETAILS_VEHICLE_PLATE_LOCATION = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_ODOMETER = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_ASSET_FRONT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_ASSET_BACK = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_INTERNAL_FRONT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_INTERNAL_BACK = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_INTERNAL_BOOT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_DRIVER_SIDE = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VEHICLE_PASSENGER_SIDE = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_REGO_PHOTO_FRONT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_REGO_PHOTO_BACK = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_BANK = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_REGO_PAPER = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_CERTIFICATE = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_PAYOUT_LETTER = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];
export const DETAILS_VIEW_DRIVER_LICENSE_AUTHENTICITY_MAPPINGS = [
  {
    key: 'TEXT_FEATURES',
    displayName: 'Text Features',
    keyPath: 'TEXT_FEATURES'
  },
  {
    key: 'BLACK_WHITE',
    displayName: 'Black and White check',
    keyPath: 'BLACK_WHITE'
  },
  {
    key: 'EXPIRY',
    displayName: 'Expiry check',
    keyPath: 'EXPIRY'
  },
  {
    key: 'PHOTO_SUBSTITUTION',
    displayName: 'Photo substitution check',
    keyPath: 'PHOTO_SUBSTITUTION'
  },
  {
    key: 'VISUAL_INTEGRITY',
    displayName: 'Visual Integrity',
    keyPath: 'VISUAL_INTEGRITY'
  },
  {
    key: 'mrzCheck',
    displayName: 'MRZ Check',
    keyPath: 'mrzCheck'
  },
  {
    key: 'officialSourceCheck',
    displayName: 'Official source check',
    keyPath: 'officialSourceCheck'
  }
];
