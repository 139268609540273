/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosError } from 'axios';
import { CURRENT_USER, REFRESH_TOKEN } from './paths';
import config from '@config';
import _startsWith from 'lodash/startsWith';
import { parseISO } from 'date-fns';
const axiosInstance = axios.create({
  baseURL: config.api
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    // Skip token handling for /api/token/ requests
    if (config.url.startsWith('/api/token/') || config.url.includes('/login')) {
      return config;
    }

    const refreshToken: any = localStorage.getItem('refreshToken');
    let accessToken: any = localStorage.getItem('accessToken');
    const expiry = localStorage.getItem('expiry');
    const now = new Date();

    // Function to check if the token is expired
    const isTokenExpired = (expiry: string) => {
      const expiryDate = Number(expiry) || parseISO(expiry).getTime();
      return now.getTime() > expiryDate;
    };

    // If token is expired or missing, refresh it before making the request
    if (!accessToken || (expiry && isTokenExpired(expiry))) {
      try {
        const clientId = localStorage.getItem('clientId');
        if (!refreshToken) {
          const sellerToken = localStorage.getItem('sellerToken');
          if (sellerToken) {
            window.location.href = `/inspection/login#token=${sellerToken}`;
          } else {
            window.dispatchEvent(
              new CustomEvent('navigate', {
                detail: { path: '/login' }
              })
            );
          }
          return Promise.reject(new Error('No refresh token available'));
        }
        const refreshResponse = await post(
          REFRESH_TOKEN,
          { refreshToken, clientId },
          false
        );
        accessToken = refreshResponse.accessToken;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('expiry', refreshResponse.expiry);
      } catch (error) {
        console.error('Error refreshing token:', error);
        const sellerToken = localStorage.getItem('sellerToken');
        if (sellerToken) {
          window.location.href = `/inspection/login#token=${sellerToken}`;
        } else {
          window.dispatchEvent(
            new CustomEvent('navigate', {
              detail: { path: '/login' }
            })
          );
        }
        return Promise.reject(error);
      }
    }

    // Set the Authorization header for all requests except login
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// List of URLs that don't require the identity parameter

const handleErrors = async (error: AxiosError) => {
  if (error.response) {
    window.onbeforeunload = null;
    if (
      error.response.status === 401 &&
      error.response.config.url !== REFRESH_TOKEN
    ) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        const sellerToken = localStorage.getItem('sellerToken');
        if (sellerToken) {
          window.location.href = `/inspection/login#token=${sellerToken}`;
        } else {
          window.dispatchEvent(
            new CustomEvent('navigate', {
              detail: { path: '/login' }
            })
          );
        }
      }
      const { accessToken, expiry } = await post(REFRESH_TOKEN, {
        refreshToken
      });
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('expiry', expiry);
    }
    if (
      localStorage.getItem('accessToken') === null &&
      !_startsWith(error.request.url, '/api/token/')
    ) {
      const sellerToken = localStorage.getItem('sellerToken');
      if (sellerToken) {
        window.location.href = `/inspection/login#token=${sellerToken}`;
      } else {
        window.dispatchEvent(
          new CustomEvent('navigate', {
            detail: { path: '/login' }
          })
        );
      }
    }
    if (error.response.status === 500) {
      return error.response.data;
    }
    if (error.response.status === 422) {
      return error.response.data;
    }
    console.error('Response error:', error.response.data);
    //@ts-ignore
    if (error.response.data?.errors?.length > 0) {
      //@ts-ignore
      alert(error.response.data?.errors[0].message);
    }
  } else if (error.request) {
    console.error('Request error:', error.request);
  } else {
    console.error('Error:', error.message);
  }
  return error;
};

const get = (url: string, params = {}) => {
  return axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch(handleErrors);
};

// Modified post method
const post = (url: string, data = {}, useGlobalCatch = true, config = {}) => {
  return axiosInstance
    .post(url, data, config)
    .then((response) => response.data)
    .catch((error) => {
      if (useGlobalCatch) {
        throw handleErrors(error);
      } else {
        throw error;
      }
    });
};

// Modified put method
const put = (url: string, data = {}) => {
  return axiosInstance
    .put(url, data)
    .then((response) => response.data)
    .catch(handleErrors);
};

const remove = (url: string) => {
  return axiosInstance
    .delete(url)
    .then((response) => response.data)
    .catch(handleErrors);
};

export { get, post, put, remove };
