import _map from 'lodash/map';
import _find from 'lodash/findLast';
import { IDocProps } from '@interface';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow_grey_up_right_icon.svg';
import StatusChip from '@components/widgets/StatusChip';
import { INSPECTION_DOCUMENT_STATUS_VARIANT, DOCUMENT_LIST } from '@constants';
import {
  InspectionStatusEnum,
  InspectionDocumentStatusEnum,
  InspectionDocumentLabelStatusEnum,
  InspectionSecurityStatusEnum,
  LenderDocumentTypeEnum
} from '@types';
import clsx from 'clsx';
import _intersection from 'lodash/intersection';
import { useState, useEffect } from 'react';
import { useInspectionDetailStore } from '@stores';
import { useLocation } from 'react-router-dom';
const Documents = ({ docType, data, activeStep }: IDocProps) => {
  const {
    setActivePanel,
    setCollapse,
    inspectionDetailData,
    setActivity,
    setVariant,
    setStatusChipLabel
  } = useInspectionDetailStore();
  const location = useLocation();
  const [showDocuments, setShowDocuments] = useState(true);
  useEffect(() => {
    const documentTypes = _intersection(inspectionDetailData.documentTypes, [
      LenderDocumentTypeEnum.ASSET_BACK,
      LenderDocumentTypeEnum.ASSET_FRONT,
      LenderDocumentTypeEnum.ASSET_LEFT,
      LenderDocumentTypeEnum.ASSET_RIGHT,
      LenderDocumentTypeEnum.INTERNAL_FRONT,
      LenderDocumentTypeEnum.INTERNAL_BACK,
      LenderDocumentTypeEnum.INTERNAL_BOOT,
      LenderDocumentTypeEnum.VIN,
      LenderDocumentTypeEnum.REGO_PLATE,
      LenderDocumentTypeEnum.ODOMETER
    ]);

    if (documentTypes.length === 0 && activeStep === '2') {
      setShowDocuments(false);
    } else {
      setShowDocuments(true);
    }
  }, [inspectionDetailData.documentTypes, activeStep]);
  const handleClick = (status: any, key: string) => {
    if (
      status === InspectionDocumentStatusEnum.Completed ||
      status === InspectionDocumentStatusEnum.inprogress
    ) {
      setCollapse(false);
      setActivePanel(key);
      setActivity(false);
      setVariant(
        INSPECTION_DOCUMENT_STATUS_VARIANT[
          status as InspectionDocumentStatusEnum
        ]
      );
      setStatusChipLabel(
        InspectionDocumentLabelStatusEnum[
          status as keyof typeof InspectionDocumentLabelStatusEnum
        ]
      );
    }
  };
  return (
    <>
      {showDocuments && (
        <>
          <div className="text-md">
            <h1 className="text-black font-semibold">Documents</h1>
          </div>
          <div className="rounded-lg border border-grey-200 shadow-xs  w-full flex flex-col justify-center h-min">
            {_map(
              DOCUMENT_LIST[parseInt(activeStep) - 1],
              (key: any, index) => {
                const files = inspectionDetailData.files;
                const docFile = _find(
                  files,
                  (item: any) =>
                    item.documentType == key ||
                    (key === 'registration' &&
                      item.documentType === 'REGO_PAPERS')
                );
                let status = inspectionDetailData?.documents[key]
                  ? ![
                      'VIN',
                      'REGO_PAPERS',
                      'REGO_PLATE',
                      'BANK_STATEMENTS'
                    ].includes(key) || inspectionDetailData?.documents[key]
                    ? 'complete'
                    : 'inprogress'
                  : 'incomplete';
                if (inspectionDetailData?.documents[key]?.needsResubmit) {
                  status = 'ResupplyPending';
                }
                if (
                  activeStep === '1' &&
                  data &&
                  data.licenseEdits &&
                  data.licenseEdits.status ===
                    InspectionSecurityStatusEnum.notProvided
                ) {
                  status = 'NOT_PROVIDED';
                  if (inspectionDetailData?.documents[key]) {
                    status = 'complete';
                    if (inspectionDetailData?.documents[key]?.needsResubmit) {
                      status = 'ResupplyPending';
                    }
                  }
                }
                if (key === 'SAFETY_CERT' || key === 'ROADWORTHY_CERT') {
                  if (inspectionDetailData?.documents[key]?.isNotProvided) {
                    status = 'NOT_PROVIDED';
                  }
                }
                const label = docType[key as keyof typeof docType];
                return (
                  (inspectionDetailData.documentTypes.includes(
                    key === 'registration' ? 'REGO_PAPERS' : key
                  ) ||
                    (parseInt(activeStep) === 1 &&
                      inspectionDetailData.documentTypes.includes(
                        'SELLER_ID'
                      ))) && (
                    <div
                      className={clsx(
                        'flex border-t px-3.5 py-3 first:border-t-0 items-center justify-between',
                        inspectionDetailData.status !==
                          InspectionStatusEnum.Initiated &&
                          'hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200',
                        (status === InspectionDocumentStatusEnum.Completed ||
                          status === InspectionDocumentStatusEnum.inprogress) &&
                          'cursor-pointer'
                      )}
                      key={index}
                      onClick={() => handleClick(status, key)}>
                      <div className="flex flex-row items-center gap-3">
                        <span className="text-md font-semibold text-black">
                          {label}
                        </span>
                      </div>
                      <div className="justify-self-end flex flex-row justify-center gap-x-6">
                        {(status === InspectionDocumentStatusEnum.Completed ||
                          status ===
                            InspectionDocumentStatusEnum.inprogress) && (
                          <span className="flex flex-row font-semibold justify-center items-center text-sm text-grey-600">
                            <span className="underline">Details</span>
                            <ArrowIcon />
                          </span>
                        )}
                        <StatusChip
                          variant={
                            INSPECTION_DOCUMENT_STATUS_VARIANT[
                              status as InspectionDocumentStatusEnum
                            ]
                          }
                          label={
                            InspectionDocumentLabelStatusEnum[
                              status as keyof typeof InspectionDocumentLabelStatusEnum
                            ]
                          }
                        />
                      </div>
                    </div>
                  )
                );
              }
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Documents;
