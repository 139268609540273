import { useEffect, useState } from 'react';
import clsx from 'clsx';
/* eslint-disable react/no-unescaped-entities */
import { ReactComponent as CloseIcon } from '@assets/icons/x_close_icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import {
  InspectionDriverLicenseSection,
  InspectionVehicleDetailsSection,
  InspectionRegoPapersSection,
  InspectionBankSection,
  InspectionRoadworthyCertSection,
  InspectionPayoutLetterSection
} from '@types';
import DriverLicense from '@pages/Inspections/components/CheckInfo/DriverLicense';
import VehicleDetails from '@pages/Inspections/components/CheckInfo/VehicleDetails';
import RegoPaper from '@pages/Inspections/components/CheckInfo/RegoPaper';
import BankStatement from '@pages/Inspections/components/CheckInfo/BankStatement';
import Cert from '@pages/Inspections/components/CheckInfo/Cert';
import ActivityHistory from '@pages/Inspections/components/ActivityHistory';
import { useInspectionDetailStore } from '@stores';
import StatusChip from '../../../../components/widgets/StatusChip';
import { useMediaQuery } from 'usehooks-ts';

export const RightDetailsBar = () => {
  const {
    activeStep,
    isCollapsed,
    showActivity,
    activePanel,
    setCollapse,
    variant,
    statusChipLabel
  } = useInspectionDetailStore();
  const docTypes = [
    InspectionDriverLicenseSection,
    InspectionVehicleDetailsSection,
    InspectionRegoPapersSection,
    InspectionBankSection,
    InspectionRoadworthyCertSection,
    InspectionPayoutLetterSection
  ];
  const isMobile = useMediaQuery('(max-width: 720px)');
  const [currentSection, setCurrentSection] = useState<any>(
    InspectionDriverLicenseSection
  );
  useEffect(() => {
    setCurrentSection(docTypes[parseInt(activeStep) - 1]);
  }, [activeStep, activePanel]);
  const showContent = () => {
    switch (activeStep) {
      case '1':
        return <DriverLicense />;
      case '2':
        return <VehicleDetails />;
      case '3':
        return <RegoPaper />;
      case '4':
        return <BankStatement />;
      case '5':
        return <Cert />;
      case '6':
        return <Cert />;
      default:
        break;
    }
  };
  const handleClose = () => {
    setCollapse(true);
  };

  return (
    <div
      className={clsx(
        'fixed bg-opacity-0 w-screen min-h-screen flex ',
        isCollapsed ? '!w-0' : 'z-70'
      )}>
      <div
        className={clsx(
          'w-full h-full',
          isCollapsed || isMobile ? 'hidden' : 'z-80 fixed'
        )}
        onClick={handleClose}></div>
      <div
        className={clsx(
          'flex flex-col sidebarMenu fixed h-full right-0 bg-white xl:w-1/4 lg:w-1/3 md:w-1/2 w-4/5 border shadow-xl rightBar z-100  overflow-y-scroll no-scrollbar clear-both',
          isCollapsed ? 'collapsed' : 'expanded'
        )}>
        <div className="flex flex-col h-full">
          {!showActivity && (
            <div
              className={clsx(
                'flex p-6 gap-3',
                statusChipLabel && statusChipLabel.length > 16 && 'flex-col'
              )}>
              <h3 className="text-lg font-semibold text-grey-900 whitespace-nowrap">
                {activePanel.activeSection === 'vehiclePhotos' ? 'AI ' : ''}
                {currentSection[activePanel.activeSection]}
              </h3>
              <StatusChip
                variant={variant}
                clsName={clsx(
                  'border text-xs font-normal flex justify-center items-center whitespace-nowrap'
                )}
                label={statusChipLabel}
              />
            </div>
          )}
          <div
            className={clsx(
              'flex p-6 absolute right-0 justify-center items-start mt-0 z-10',
              isCollapsed && 'hidden'
            )}>
            <div
              className="cursor-pointer hover:shadow-paper hover:border"
              onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          {showActivity && <ActivityHistory />}
          {!showActivity && showContent()}
        </div>
      </div>
    </div>
  );
};
export default RightDetailsBar;
