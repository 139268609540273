import OnboardingLayout from '@components/layouts/OnboardingLayout';
import { NewInspectionModalEnum } from '@types';

import React, { useState, useEffect, useRef } from 'react';
import Button from '@components/forms/Button';
import { Chip, Radio } from '@material-tailwind/react';
import {
  CREATE_INSPECTION_TEMPLATE,
  GET_INSPECTION_TEMPLATE,
  UPDATE_INSPECTION_TEMPLATE,
  post
} from '@services';
import ConfirmInspectionModal from '@components/widgets/ConfirmInspectionModal';
import { DOCUMENT_TYPE } from '@constants';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import { useForm } from 'react-hook-form';
import { Input } from '@components/forms';
import phone from '@assets/images/phone.png';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useNavigate } from 'react-router-dom';
import { useTemplateStore } from '@stores';
const CreateTemplate = () => {
  const { templateObject } = useTemplateStore();
  const [step, setStep] = useState(0);
  const [backToDashboard, setBackToDashboard] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState(NewInspectionModalEnum.close);
  const [successSubmit, setSuccessSubmit] = useState(false);

  const [open, setOpen] = useState(true);
  const [isBuyer, setIsBuyer] = useState(true);
  const [selectedDocuments, setSelected] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [showSuccessMsg, setSuccessMsg] = useState(false);
  const [documentsError, setDocumentsError] = useState('');
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLDivElement>(null);
  const [template, setTemplate] = useState<any>(templateObject);
  const [inspectionMessage, setInspectionMessage] = useState<string>(
    'Hi {SellerFirstName} {SellerLastName}, {BuyerFirstName} {BuyerLastName} is interested in purchasing your vehicle. {OrganisationName} needs to verify some details about you and the vehicle before proceeding.\n\n' +
      'Please complete our online inspection process before {ExpiryDate}, after which it will expire.  This process will take {ProcessDurationMinutes} minutes and you will require:\n' +
      '{DocumentList}\n\n' +
      'Get started - {InspectionLink}'
  );
  const [templateHtml, setTemplateHtml] = useState<any>('');
  const [errorMegLength, setErrorMsgLength] = useState<any>(0);
  const liList = [
    '{SellerFirstName}',
    '{SellerLastName}',
    '{BuyerFirstName}',
    '{BuyerLastName}',
    '{OrganisationName}',
    '{ExpiryDate}',
    '{ProcessDurationMinutes}',
    '{DocumentList}',
    '{InspectionLink}'
  ];
  const templateMsg = {
    SellerFirstName: 'Sellerfirst',
    SellerLastName: 'Sellerlast',
    BuyerFirstName: 'Buyerfirst',
    BuyerLastName: 'Buyerlast',
    OrganisationName: 'Acme Corp',
    ExpiryDate: 'dd/mm/yyyy',
    ProcessDurationMinutes: 'X',
    DocumentList: 'Document 1, Document 2, Document 3',
    InspectionLink:
      'https://authentik8.com.au/inspection/login#token=1234567890123456789012345'
  };
  const onDashboardClick = () => {
    setBackToDashboard(true);
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };
  const onCloseClick = () => {
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };

  const {
    register,
    setError,
    setFocus,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const lender = localStorage.getItem(`lenders_${userId}` as any);
    const organisationName = lender
      ? JSON.parse(lender)[0]?.organisationName
      : '';
    templateMsg.OrganisationName = organisationName;
    const newDocStr = (() => {
      const list = new Set<string>();

      // Check for driver's license
      if (selectedDocuments.includes(LenderDocumentTypeEnum.SELLER_ID)) {
        list.add("driver's license");
      }

      // Check for vehicle access related documents
      const vehicleAccessDocs = [
        LenderDocumentTypeEnum.VIN,
        LenderDocumentTypeEnum.REGO_PLATE,
        LenderDocumentTypeEnum.ODOMETER,
        LenderDocumentTypeEnum.ASSET_FRONT,
        LenderDocumentTypeEnum.ASSET_BACK,
        LenderDocumentTypeEnum.INTERNAL_FRONT,
        LenderDocumentTypeEnum.INTERNAL_BACK,
        LenderDocumentTypeEnum.INTERNAL_BOOT,
        LenderDocumentTypeEnum.ASSET_LEFT,
        LenderDocumentTypeEnum.ASSET_RIGHT
      ];
      if (vehicleAccessDocs.some((doc) => selectedDocuments.includes(doc))) {
        list.add('access to the vehicle');
      }

      // Check for registration certificate
      if (selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS)) {
        list.add('registration certificate');
      }

      // Check for bank statement
      if (selectedDocuments.includes(LenderDocumentTypeEnum.BANK_STATEMENTS)) {
        list.add('recent bank statement');
      }

      // // Check for roadworthy/safety certificate
      // if (
      //   selectedDocuments.includes(LenderDocumentTypeEnum.ROADWORTHY_CERT) ||
      //   selectedDocuments.includes(LenderDocumentTypeEnum.SAFETY_CERT)
      // ) {
      //   list.add('roadworthy / safety certificate');
      // }
      const items = Array.from(list);
      // If there's only one item, return it directly
      if (items.length <= 1) return items.join('');

      // For multiple items, join with commas and add "and" before the last item
      return items.slice(0, -1).join(', \n') + ' and \n' + items.slice(-1);
    })();
    const fullTemplateMsg = {
      ...templateMsg,
      DocumentList: newDocStr
    };

    let newTemplateHtml = inspectionMessage;
    Object.entries(fullTemplateMsg).forEach(([key, value]) => {
      if (key === 'InspectionLink') {
        newTemplateHtml = newTemplateHtml.replace(
          new RegExp(`{${key}}`, 'g'),
          `<span style="word-break: break-all;">${value}</span>`
        );
      } else {
        newTemplateHtml = newTemplateHtml.replace(
          new RegExp(`{${key}}`, 'g'),
          value as string
        );
      }
    });

    setTemplateHtml(newTemplateHtml);
    const charCount = newTemplateHtml.replace(/<[^>]+>/g, '').length;

    if (charCount > 600) {
      setErrorMsgLength(charCount);
    } else {
      setErrorMsgLength(0);
    }
  }, [selectedDocuments, inspectionMessage]);
  function onCloseModalClick() {
    setIsOpenModal(false);
    setBackToDashboard(false);
  }
  function handleClose() {
    setIsOpenModal(false);
    setBackToDashboard(false);
    navigate('/inspection-templates');
  }
  const setLenderDocuments = (data: any) => {
    const itemElement = documentRef.current?.children[0] as HTMLElement;
    if (selectedDocuments.length === 0) {
      setError('selectedDocuments', {
        type: 'custom',
        message: 'Please select at least one document.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check NEVDIS dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.VIN) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PLATE) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'VIN, Number Plate or Registration Certificate must be included when NEVDIS is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check VIN dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.VIN) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when VIN is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check Registration Certificate dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when Registration Certificate is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check Number Plate dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PLATE) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when Number Plate is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    if (errorMegLength > 640) {
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('inspectionMessage');
      return;
    }
    // Check buyer details usage when not required
    if (
      !isBuyer &&
      (inspectionMessage.includes('{BuyerFirstName}') ||
        inspectionMessage.includes('{BuyerLastName}'))
    ) {
      setFocus('inspectionMessage');
      return;
    }

    // Check for required inspection link
    if (!inspectionMessage.includes('{InspectionLink}')) {
      setFocus('inspectionMessage');
      return;
    }
    const params: any = {
      assetType: 'CAR',
      name: data.name,
      description: data.description,
      buyerRequired: isBuyer,
      documentTypes: selectedDocuments
    };
    if (inspectionMessage !== '') {
      params.sellerInviteMessage = inspectionMessage;
    }
    if (template && template.templateId) {
      post(
        UPDATE_INSPECTION_TEMPLATE,
        {
          ...params,
          templateId: template.templateId
        },
        false
      )
        .then((data) => {
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
            navigate('/inspection-templates');
          }, 3000);
        })
        .catch((e: any) => {
          alert(e.response.data.error.message);
        });
    } else {
      const userId = localStorage.getItem('userId');

      const lenders = localStorage.getItem(`lenders_${userId}`);
      if (lenders != null) {
        const lender = JSON.parse(lenders)[0];
        const organisationId = lender.organisationId;
        post(
          CREATE_INSPECTION_TEMPLATE,
          {
            assetType: 'CAR',
            lenderOrganisationId: organisationId,
            name: data.name,
            description: data.description,
            buyerRequired: isBuyer,
            sellerInviteMessage: inspectionMessage,
            documentTypes: selectedDocuments
          },
          false
        )
          .then((data) => {
            setSuccessMsg(true);
            setTemplate(data);
            setTimeout(() => {
              setSuccessMsg(false);
              navigate('/inspection-templates');
            }, 3000);
          })
          .catch((e: any) => {
            alert(e.response.data.error.message);
          });
      }
    }
  };
  const removeItems = (key: any) => {
    const filters = selectedDocuments.filter(
      (item) =>
        item !== key &&
        ![
          LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
          LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
          LenderDocumentTypeEnum.FACE
        ].includes(item as LenderDocumentTypeEnum)
    );
    setSelected(filters);
    const removeOptions = selectedOptions.filter((item) => item.value !== key);
    setSelectedOptions(removeOptions);
  };
  const onMultiSelectChange = (e: { label: string; value: string }[]) => {
    setSelectedOptions(e);
    clearErrors();
    const selectValues = e.map((item) => item.value);
    setSelected(selectValues);
  };

  const ArrowRenderer = ({ expanded }: { expanded: any }) => (
    <div
      className={clsx(
        'grid place-items-center absolute top-2/4 right-2 pt-px w-5 h-5 text-blue-gray-400 rotate-0 -translate-y-2/4 transition-all',
        expanded && 'rotate-180'
      )}>
      <ArrowDownIcon />
    </div>
  );

  ArrowRenderer.propTypes = {
    expanded: PropTypes.bool
  };

  useEffect(() => {
    if (templateObject && templateObject.templateId) {
      post(GET_INSPECTION_TEMPLATE, {
        templateId: templateObject.templateId
      }).then((res) => {
        if (res.template) {
          const targetTemplate = res.template;
          setTemplate(targetTemplate);
          setSelected(targetTemplate.documentTypes);
          setValue('name', targetTemplate.name);
          setValue('description', targetTemplate.description);
          setIsBuyer(targetTemplate.buyerRequired);
          if (targetTemplate.sellerInviteMessage) {
            setInspectionMessage(targetTemplate.sellerInviteMessage);
            setValue('inspectionMessage', targetTemplate.sellerInviteMessage);
          }
          setSelectedOptions(
            DOCUMENT_TYPE.filter((item) => {
              return targetTemplate.documentTypes.includes(item.value);
            })
          );
        }
      });
      return;
    }
    if (template === undefined && templateObject === undefined) {
      setSelected(Object.keys(LenderDocumentTypeEnum));
      return;
    }
  }, [templateObject]);
  return (
    <OnboardingLayout
      step={step}
      onChangeStep={setStep}
      successSubmit={successSubmit}
      backToDashboard={() => onDashboardClick()}
      title="Create inspection template"
      type="template"
      onClose={() => onCloseClick()}>
      <div className="max-w-[768px] w-full">
        <div
          ref={containerRef}
          className="h-full flex flex-col overflow-y-auto justify-between ">
          <div className="h-full flex flex-col overflow-y-auto">
            <div className="text-[20px] mb-4 font-semibold text-grey-900">
              Inspection Details
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Inspection template name
              </p>
              <Input
                type="text"
                {...register(`name`, { required: 'Name is required' })}
                placeholder="Inspection template name"
                onBlur={() => {
                  clearErrors();
                }}
                icon={errors.name && <ErrorIcon />}
              />
              {errors.name ? (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.name?.message?.toString()}
                </span>
              ) : (
                <span className="text-grey-600 text-2sm mt-[6px]">
                  Displays as an option when creating a new inspection
                </span>
              )}
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Description
              </p>
              <textarea
                className="w-full h-32 border rounded-lg py-3 px-3.5 gap-2 border-grey-300"
                {...register(`description`, {
                  required: 'Description is required',
                  maxLength: {
                    value: 280,
                    message:
                      'Inspection Template description cannot be longer than 280 characters.'
                  }
                })}
                placeholder="Inspection template description"
                onBlur={() => {
                  clearErrors();
                }}
              />
              {errors.description && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.description?.message?.toString()}
                </span>
              )}
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Are ‘Buyer’ details required?
              </p>

              <Radio
                name="type"
                containerProps={{
                  className: 'p-2'
                }}
                checked={isBuyer}
                onChange={() => setIsBuyer(true)}
                label="Yes"
              />
              <Radio
                name="type"
                containerProps={{
                  className: 'p-2 ml-4'
                }}
                checked={!isBuyer}
                onChange={() => setIsBuyer(false)}
                label="No"
              />
              <p className="text-grey-600 text-sm">
                Choosing ‘Yes’ to the above will require entry of Buyer First
                Name and Last Name details when creating an inspection.{' '}
              </p>
            </div>
            <div className="text-[20px] mt-8 mb-4 font-semibold text-grey-900">
              Invitation Message
            </div>
            <div className="mb-4">
              <div className="flex flex-row gap-4">
                <div className="flex-1">
                  <p className="text-grey-700 text-sm mb-1.5">
                    Inspection Invitation SMS Message
                  </p>
                  <textarea
                    className="w-full h-[388px] border rounded-lg py-3 px-3.5 gap-2 border-grey-300"
                    {...register(`inspectionMessage`, {
                      required: 'Name is required',
                      value: inspectionMessage
                    })}
                    onChange={(e) => {
                      setValue('inspectionMessage', e.target.value);
                      setInspectionMessage(e.target.value);
                    }}
                    placeholder="Inspection template description"
                    onBlur={() => {
                      clearErrors();
                    }}
                  />
                  {errorMegLength > 640 && (
                    <span className="text-error-light text-2sm mt-[6px]">
                      Message exceeds 640 characters. It may be split into
                      multiple SMS.
                    </span>
                  )}
                  {errorMegLength > 600 && errorMegLength < 640 && (
                    <span className="text-error-light text-2sm mt-[6px]">
                      Message is approaching 640 characters limit.
                    </span>
                  )}
                  {!isBuyer &&
                    (inspectionMessage.includes('{BuyerFirstName}') ||
                      inspectionMessage.includes('{BuyerLastName}')) && (
                      <div className="text-error-light text-2sm mt-[6px]">
                        {'{BuyerFirstName} '} and {'{BuyerLastName} '}
                        personalisation variables cannot be used when Buyer
                        details are not required in the inspection.
                      </div>
                    )}
                  {!inspectionMessage.includes('{InspectionLink}') &&
                    inspectionMessage !== '' && (
                      <div className="text-error-light text-2sm mt-[6px]">
                        Message must include the {'{InspectionLink} '} variable.
                      </div>
                    )}
                  <div className="flex flex-col">
                    <p className="text-grey-600 text-xs mb-4">
                      Personalisation variables:
                    </p>
                    <ul className="text-grey-600 text-xs mb-1.5 list-disc">
                      {liList.map((item, index) => (
                        <li className="ml-4 mb-1" key={index}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="relative">
                    <img src={phone} alt="phone" className="w-[376px]" />
                    <div className="absolute border rounded-[12px] p-2 top-[140px] left-[30px] w-[316px] bg-[#EAECF0]">
                      {templateHtml
                        .split('\n')
                        .map((paragraph: string, index: number) => (
                          <React.Fragment key={index}>
                            {paragraph
                              .split(/(<span.*?<\/span>)/)
                              .map((part, partIndex) => {
                                if (part.startsWith('<span')) {
                                  // Parse the span element
                                  const match = part.match(
                                    /<span style="([^"]*)">(.*?)<\/span>/
                                  );
                                  if (match) {
                                    const [, className, content] = match;
                                    return (
                                      <span
                                        key={partIndex}
                                        style={{
                                          wordBreak: 'break-all'
                                        }}>
                                        {content}
                                      </span>
                                    );
                                  }
                                }
                                return part;
                              })}
                            {index < templateHtml.split('\n').length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={documentRef}
              className="text-[20px] mt-8 mb-4 font-semibold text-grey-900">
              Documents & Verifications
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Add documents
              </p>
              <MultiSelect
                options={DOCUMENT_TYPE}
                value={selectedOptions}
                className="border-grey-300 mb-1.5 shadow-sm rounded-md w-full !h-10 text-sm text-grey-400 ring-1 ring-grey-900/10 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
                ArrowRenderer={ArrowRenderer}
                onChange={onMultiSelectChange}
                labelledBy="Add additional documents"
              />
              {errors.selectedDocuments && (
                <span className="text-error-500 text-2sm mt-[6px]">
                  {errors.selectedDocuments?.message?.toString()}
                </span>
              )}
              <p className="text-grey-600 text-sm">
                Select all the documents you require for your inspections.
              </p>
            </div>
            <div className="min-h-[380px]">
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.SELLER_ID
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Seller Identification
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.SELLER_ID
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.ASSET_BACK,
                LenderDocumentTypeEnum.ASSET_FRONT,
                LenderDocumentTypeEnum.ASSET_LEFT,
                LenderDocumentTypeEnum.ASSET_RIGHT,
                LenderDocumentTypeEnum.INTERNAL_FRONT,
                LenderDocumentTypeEnum.INTERNAL_BACK,
                LenderDocumentTypeEnum.INTERNAL_BOOT,
                LenderDocumentTypeEnum.VIN,
                LenderDocumentTypeEnum.REGO_PLATE,
                LenderDocumentTypeEnum.ODOMETER
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Vehicle
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.ASSET_BACK,
                      LenderDocumentTypeEnum.ASSET_FRONT,
                      LenderDocumentTypeEnum.ASSET_LEFT,
                      LenderDocumentTypeEnum.ASSET_RIGHT,
                      LenderDocumentTypeEnum.INTERNAL_FRONT,
                      LenderDocumentTypeEnum.INTERNAL_BACK,
                      LenderDocumentTypeEnum.INTERNAL_BOOT,
                      LenderDocumentTypeEnum.VIN,
                      LenderDocumentTypeEnum.REGO_PLATE,
                      LenderDocumentTypeEnum.ODOMETER
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.REGO_PAPERS
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Registration Certificate
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.REGO_PAPERS
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.BANK_STATEMENTS,
                LenderDocumentTypeEnum.PAYOUT_LETTER
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Financial Documentation
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.BANK_STATEMENTS,
                      LenderDocumentTypeEnum.PAYOUT_LETTER
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.ROADWORTHY_CERT
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Roadworthy / Safety Certificate
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.ROADWORTHY_CERT
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.PPSR,
                LenderDocumentTypeEnum.RAV,
                LenderDocumentTypeEnum.NEVDIS,
                LenderDocumentTypeEnum.GLASSES
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    External Verifications
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.PPSR,
                      LenderDocumentTypeEnum.RAV,
                      LenderDocumentTypeEnum.NEVDIS,
                      LenderDocumentTypeEnum.GLASSES
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 justify-end">
            {showSuccessMsg && (
              <div className="text-success-700 sticky font-medium text-sm text-right my-3">
                Inspection templates saved!
              </div>
            )}
            <div className="flex gap-4 justify-end">
              <Button
                className="font-semibold bg-primary w-auto capitalize"
                onClick={() => {
                  handleSubmit(setLenderDocuments, () => {
                    const itemElement = containerRef.current
                      ?.children[0] as HTMLElement;
                    itemElement?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start'
                    });
                  })();
                }}>
                Save Inspection Template
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmInspectionModal
        open={isOpenModal}
        type={modalType}
        onSubmit={() => handleClose()}
        onClose={() => onCloseModalClick()}
      />
    </OnboardingLayout>
  );
};
export default CreateTemplate;
